import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { getAuthState } from 'redux/auth/selector';
import { useAppSelector } from 'redux/redux';

/**
 * The PublicRoutes component redirects to the dashboard if the user is logged in, otherwise it renders
 * the child components.
 */
const PublicRoutes: FC = () => {
  const userData = useAppSelector(getAuthState);
  const isLogin = userData.isLoggedIn
  const isAdmin = userData.isAdmin
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isLogin && isAdmin) {
      navigate(`/${ROUTES.DASHBOARD}`);
    }
    else if(isLogin && !isAdmin){
      navigate(`/${ROUTES.RETAILER_STATS}`);
    }
  }, [isLogin]);

  return <Outlet />;
};

export default PublicRoutes;
