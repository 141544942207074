import { Company, Retailer } from 'models/retailer-interface';

export interface InitialRetailersState {
  isLoading: boolean;
  currentPage: number;
  retailersData: Retailer[];
  retailerDetail: Retailer | null;
  count: number | null;
  sortingKey: string | null;
  sortingOrder: number | null;
  selectedRetailer: Retailer | null;
  companyDetails: Company | null;
}

const initialRetailersState: InitialRetailersState = {
  isLoading: false,
  currentPage: 1,
  retailersData: [],
  retailerDetail: null,
  count: null,
  sortingKey: null,
  sortingOrder: null,
  selectedRetailer: null,
  companyDetails: null,
};

export default initialRetailersState;
