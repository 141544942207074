import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { HttpError } from 'models/http-interface';
import { Company, Retailer } from 'models/retailer-interface';
import { setFavicon } from 'utils/favicon';
import { formatError } from 'utils/http-error-formatter';
import { showError } from 'utils/toast-alerts';
import initialState from './initial-state';
import {
  createRetailerAsync,
  deleteRetailerAsync,
  getRetailerListAsync,
  getRetailerStats,
  sendResetPasswordEmailAsync,
  updateRetailerAsync,
  verifyCompaniesId,
  verifyRetailerAsync
} from './thunk';

export const retailerSlice = createSlice({
  name: 'retailers',
  initialState,
  reducers: {
    setRetailerData: (state, action: PayloadAction<Retailer>) => {
      state.retailerDetail = action.payload;
    },

    updateRetailersSortingKeyAndOrder: (
      state,
      action: PayloadAction<{
        sortingKeyOrder: {
          sortingKey: string | null;
          sortingOrder: number | null;
        };
      }>
    ) => {
      state.sortingKey = action.payload.sortingKeyOrder.sortingKey;
      state.sortingOrder = action.payload.sortingKeyOrder.sortingOrder;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createRetailerAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRetailerListAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRetailerListAsync.fulfilled, (state, { payload }) => {
        state.retailersData = payload.data.list;
        state.count = payload.data.count;
        state.isLoading = false;
      })
      .addCase(getRetailerListAsync.rejected, (state, action) => {
        state.isLoading = false;
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
      })
      .addCase(deleteRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteRetailerAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(updateRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRetailerAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateRetailerAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(verifyRetailerAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyRetailerAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedRetailer = action.payload.data;
      })
      .addCase(verifyRetailerAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(verifyCompaniesId.pending, (state) => {
        state.isLoading = true;
        state.selectedRetailer = null;
      })
      .addCase(verifyCompaniesId.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.companyDetails = payload.data;

        setFavicon((payload.data as Company).favicon);
      })
      .addCase(verifyCompaniesId.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(sendResetPasswordEmailAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendResetPasswordEmailAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(sendResetPasswordEmailAsync.rejected, (state, action) => {
        const errorMsg = formatError((action.payload as HttpError).error);
        showError(errorMsg);
        state.isLoading = false;
      })
      .addCase(getRetailerStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRetailerStats.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getRetailerStats.rejected, (state, action) => {
      })
  },
});

export const { setRetailerData, updateRetailersSortingKeyAndOrder } =
  retailerSlice.actions;

export default retailerSlice.reducer;
