import axiosInstance from "api/api-service";
import { COMPANY, RETAILERS } from "constants/api-endpoints";
import { TIME_FRAMES } from "constants/enums";
import { HttpResponse } from "models/http-interface";
import { ListParams } from "models/retailer-interface";

/**
 * @desc: create retailer
 * @param body
 * @returns
 */
export const createRetailer = (body: FormData) => {
  return axiosInstance.post<HttpResponse<null>>(`${RETAILERS.RETAILER}`, body);
};

/**
 * @desc: delete retailer
 * @param retailer_id
 * @returns
 */
export const deleteRetailer = (retailer_id: string) => {
  return axiosInstance.delete<HttpResponse<null>>(
    `${RETAILERS.RETAILER}/${retailer_id}`
  );
};

/**
 * @desc: get all retailers
 * @param params
 * @returns
 */
export const getRetailersList = (params: ListParams) => {
  return axiosInstance.get<any>(`${RETAILERS.RETAILERS}`, {
    params,
  });
};

/**
 * @desc: update retailer by id
 * @param id
 * @param updatedBody
 * @returns
 */
export const updateRetailer = (id: string, updatedBody: FormData) => {
  return axiosInstance.put<HttpResponse<null>>(
    `${RETAILERS.RETAILER}/${id}`,
    updatedBody
  );
};

/**
 * @desc: to verify the reset password token
 * @param token
 * @returns
 */
export const checkCompanyId = () =>
  axiosInstance.get<HttpResponse<null>>(`${COMPANY.COMPANY}`);

/**
 * @desc: verify the retailer
 * @param params
 * @returns
 */
export const verifyRetailer = (retailerId: string) => {
  return axiosInstance.get<any>(`${RETAILERS.RETAILER}/${retailerId}`);
};

/**
 * @desc: to send reset password email
 * @param retailer_id
 * @returns
 */
export const sendResetPasswordEmail = (retailer_id: string) => {
  return axiosInstance.post<HttpResponse<null>>(
    `${RETAILERS.RETAILER_RESET_PASSWORD}`,
    { retailer_id }
  );
};

/**
 * @desc: to get stats
 * @param retailer_id
 * @returns
 */
export const getRetailerStatistics = (data: {
  retailerId: string;
  startDate: Date;
  endDate: Date;
}) => {
  return axiosInstance.post<HttpResponse<null>>(
    `${RETAILERS.RETAILERS_STATS}`,
    data
  );
};
export const getRetailerGraphs = (data: {
  retailerId: string;
  startDate: Date;
  endDate: Date;
  timeFrame: TIME_FRAMES;
}) => {
  return axiosInstance.post<HttpResponse<null>>(
    `${RETAILERS.RETAILERS_GRAPH}`,
    data
  );
};
