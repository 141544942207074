import { Box } from '@mui/material';
import Button from 'components/UI/Button';
import LoadingButton from 'components/UI/LoadingButton';
import InputField from 'components/UI/form/InputField';
import { ROUTES } from 'constants/routes';
import { Form, Formik } from 'formik';
import useTranslate from 'hooks/useTranslate';
import { HttpError } from 'models/http-interface';
import { CreateRetailer } from 'models/retailer-interface';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'redux/redux';
import { selectLoading } from 'redux/retailers/selector';
import { createRetailerAsync } from 'redux/retailers/thunk';
import { formatError } from 'utils/http-error-formatter';
import { showError, showSuccess } from 'utils/toast-alerts';
import { AddRetailerSchema } from 'utils/validation-schema';
// import ColorPickerField from "components/UI/form/ColorPickerField";
import FileInputField from 'components/UI/form/FileInputField';

/**
 * @desc: add retailer form
 * @returns
 */
const AddRetailer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const popoverRef = useRef(null);

  const { parseUI } = useTranslate();

  const loading = useAppSelector(selectLoading);

  /**
   * @desc: to close the color picker when user clicks outside the color picker
   * @param event
   */
  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setShowPicker(false);
    }
  };

  /**
   * @desc: to close the color picker when user clicks outside the color picker
   */
  useEffect(() => {
    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const initialValues: CreateRetailer = {
    name: '',
    // color: "",
    logo: undefined,
    place: '',
  };

  /**
   * @desc: to handle the submit event
   * @param values
   */
  const handleSubmit = async (values: CreateRetailer) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    try {
      /** create retailer */
      const response = await dispatch(
        createRetailerAsync({ pestCompanyInfo: formData })
      ).unwrap();

      showSuccess(response.data.message);
      navigate(`/${ROUTES.DASHBOARD}`);
    } catch (error) {
      const errorMsg = formatError((error as HttpError).error);
      showError(errorMsg);
      navigate(`/${ROUTES.DASHBOARD}`);
    }
  };

  /**
   * @desc: to navigate to dashboard page, when user clicks on cancel
   */
  const onCancelClick = () => {
    navigate(`/${ROUTES.DASHBOARD}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="inner-box-wrapper plr36"
      style={{
        paddingTop: 30,
        paddingBottom: 37,
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <h1>{parseUI('ADD_RETAILER')}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={AddRetailerSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form className="login-outer change-pass-form">
            <InputField
              name={'name'}
              type={'text'}
              label={'RETAILER_NAME'}
              required
            />
            <InputField name={'place'} type={'text'} label={'PLACE'} required />

            <InputField name={'email'} type={'text'} label={'EMAIL'} required />

            {/* color picker field */}
            {/* <ColorPickerField
              name="color"
              showPicker={showPicker}
              setShowPicker={setShowPicker}
              popoverRef={popoverRef}
            /> */}
            {/* file input field */}
            <FileInputField name="logo" />

            {/* submit button */}
            <div className="btn-end">
              <LoadingButton
                type="submit"
                label="SUBMIT"
                loader={loading}
                className="loading-button"
                disabled={!isValid || !dirty || loading}
              />

              <Button type="button" onClick={onCancelClick} label="CANCEL" />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddRetailer;
