import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { TOKEN } from 'constants/local-storage-keys';
import { HttpError } from 'models/http-interface';
import { formatError } from 'utils/http-error-formatter';
import { setKey } from 'utils/local-storage';
import { showError, showSuccess } from 'utils/toast-alerts';

import initialState from './initial-state';
import {
  loginUserAsync,
  updateAccessTokenAsync,
  verifyEmailForPasswordChange,
} from './thunk';
import { jwtDecode } from 'jwt-decode';

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        showSuccess(payload.message);
        state.isAdmin = payload.data.isAdmin;
        state.accessToken = payload.data.access_token;
        state.refreshToken = payload.data.refresh_token;
        state.isLoggedIn = true;
        state.userType = payload.data.user_type;
        state.referer_id = payload.data.referer_id;

        /** decode token and set referrer slug */
        const decodedToken: any = jwtDecode(payload.data.access_token);
        state.referer_slug = decodedToken?.retailerSlug ?? undefined;

        setKey(TOKEN, payload.data.access_token);
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.isLoading = false;
        const payload = action.payload as HttpError;
        const errorMsg = formatError(payload.error);
        showError(errorMsg);
      })
      .addCase(verifyEmailForPasswordChange.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailForPasswordChange.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(verifyEmailForPasswordChange.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAccessTokenAsync.fulfilled, (state, { payload }) => {
        state.accessToken = payload.data.access_token;
        state.refreshToken = payload.data.refresh_token;
      })
      .addCase(PURGE, () => {
        return initialState;
      });
  },
});

export default authSlice.reducer;
