import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TOKEN } from 'constants/local-storage-keys';
import { ROUTES } from 'constants/routes';
import { getAuthState } from 'redux/auth/selector';
import { useAppSelector } from 'redux/redux';
import { getKey } from 'utils/local-storage';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[];
}

/**
 * The PrivateRoute component is used to protect routes and redirect users to the login page if they
 * are not logged in.
 * @param  - - `PrivateRoute`: The name of the functional component being exported.
 * @returns The PrivateRoute component is returning the children components passed to it.
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  requiredRoles = [],
}) => {
  const userData = useAppSelector(getAuthState);
  const isLogin = userData.isLoggedIn;
  const isAdmin = userData.isAdmin;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin && !getKey(TOKEN)) {
      navigate(`/${ROUTES.LOGIN}`);
    }

    /** to handle role based AUTH */
    const isAdminAccessingRoute =
      !isAdmin && isLogin && requiredRoles.includes('admin');
    const isReferrerAccessibleRoute =
      isAdmin && isLogin && requiredRoles.includes('referrer');

    if (isAdminAccessingRoute || isReferrerAccessibleRoute) {
      navigate(`/`);
    }
  }, [isLogin]);

  return <>{children}</>;
};
