import axiosInstance from 'api/api-service';
import { HttpResponse } from 'models/http-interface';
import { COMPANY, USER } from 'constants/api-endpoints';
import {
  ChangePasswordEmailData,
  ChangePasswordWithTokenData,
  LoginData,
  LoginResponse,
} from 'models/user-interface';

/**
 * @desc: login user
 * @param body
 * @returns
 */
export const loginUser = (body: LoginData) => {
  return axiosInstance.post<HttpResponse<LoginResponse>>(`${USER.LOGIN}`, {
    ...body,
    type: 'admin',
  });
};

/**
 * @desc: change password
 * @param body
 * @returns
 */
export const changePassword = (data: ChangePasswordWithTokenData) => {
  const { token, retailer, ...rest } = data;
  const requestBody = {
    ...rest,
    ...(retailer && { retailer }), // Conditionally add retailer if present
  };
  return axiosInstance.put<HttpResponse<null>>(
    `${USER.CHANGE_PASSWORD}`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

/**
 * @desc: update access token, when expired using refresh token
 * @param body
 * @returns
 */
export const updateAccessToken = (body: {
  refreshToken: string;
  companySlug: string;
}) => {
  const { companySlug, ...rest } = body;
  return axiosInstance.post<
    HttpResponse<{ refresh_token: string; access_token: string }>
  >(`${USER.UPDATE_ACCESS_TOKEN}`, rest);
};

/**
 * @desc: to verify the reset password token
 * @param token
 * @returns
 */
export const resetPasswordTokenVerify = (data: {
  token: string;
  retailer?: string;
}) => {
  const { token, retailer } = data;
  const retailerQueryParam = retailer ? `&retailer=${retailer}` : '';
  return axiosInstance.get<HttpResponse<null>>(
    `${USER.VERIFY_RESET_PASSWORD_TOKEN}?token=${token}${retailerQueryParam}`
  );
};

/**
 * @desc: verify admin email for password change
 * @param body
 * @returns
 */
export const verifyEmailPasswordChange = (body: ChangePasswordEmailData) => {
  const { ...rest } = body;

  return axiosInstance.post<HttpResponse<null>>(
    `${USER.CHANGE_PASSWORD}`,
    rest
  );
};
