import { createAsyncThunk } from "@reduxjs/toolkit";
import { TIME_FRAMES } from "constants/enums";
import { ListParams, UpdateRetailerThunk } from "models/retailer-interface";
import {
  checkCompanyId,
  createRetailer,
  deleteRetailer,
  getRetailerGraphs,
  getRetailersList,
  getRetailerStatistics,
  sendResetPasswordEmail,
  updateRetailer,
  verifyRetailer,
} from "./api";

/**
 * @desc: to create a new retailer
 */
export const createRetailerAsync = createAsyncThunk(
  "retailer/create",
  async (
    { pestCompanyInfo }: { pestCompanyInfo: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await createRetailer(pestCompanyInfo);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to delete a retailer
 */
export const deleteRetailerAsync = createAsyncThunk(
  "retailer/delete",
  async ({ retailerId }: { retailerId: string }, { rejectWithValue }) => {
    try {
      const response = await deleteRetailer(retailerId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to get all the retailers list
 */
export const getRetailerListAsync = createAsyncThunk(
  "retailer/list",
  async (params: ListParams, { rejectWithValue }) => {
    try {
      const { data } = await getRetailersList(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to update a retailer
 */
export const updateRetailerAsync = createAsyncThunk(
  "retailer/update",
  async ({ retailerId, data }: UpdateRetailerThunk, { rejectWithValue }) => {
    try {
      const response = await updateRetailer(retailerId, data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: verify the company ID
 */
export const verifyCompaniesId = createAsyncThunk(
  "user/verifyCompaniesId",
  async (_data, { rejectWithValue }) => {
    try {
      const response = await checkCompanyId();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to verify the retailer, and fetch retailer detail
 */
export const verifyRetailerAsync = createAsyncThunk(
  "retailer/verify",
  async (retailerId: string, { rejectWithValue }) => {
    try {
      const { data } = await verifyRetailer(retailerId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: to send reset password email
 */
export const sendResetPasswordEmailAsync = createAsyncThunk(
  "retailer/sendResetPasswordEmail",
  async ({ retailerId }: { retailerId: string }, { rejectWithValue }) => {
    try {
      const response = await sendResetPasswordEmail(retailerId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @desc: get stats of retails by retailer id
 */
export const getRetailerStats = createAsyncThunk(
  "retailer/getRetailerStatistics",
  async (
    data: { retailerId: string; startDate: Date; endDate: Date },
    { rejectWithValue }
  ) => {
    try {
      const response = await getRetailerStatistics(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRetailerGraph = createAsyncThunk(
  "retailer/getRetailerGraph",
  async (
    data: {
      retailerId: string;
      startDate: Date;
      endDate: Date;
      timeFrame: TIME_FRAMES;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getRetailerGraphs(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
