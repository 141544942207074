import { companyDetails, selectedRetailer } from 'redux/retailers/selector';
import { useAppSelector } from 'redux/redux';

/**
 * @description: to get the company/retailer details
 * @returns
 */
const useCompanyDetails = () => {
  const companyData = useAppSelector(companyDetails);
  const retailerInfo = useAppSelector(selectedRetailer);

  const logo = retailerInfo ? retailerInfo.logo : companyData?.logo;
  const name = retailerInfo ? retailerInfo.name : companyData?.name;

  return { logo, name };
};

export default useCompanyDetails;
