import { USER_TYPES } from 'constants/enums';
import { Company } from 'models/retailer-interface';

export interface InitialAuthState {
  isLoading: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isPasswordChanged: boolean;
  companyDetails: Company | null;
  userType: USER_TYPES | undefined;
  referer_id: string | undefined;
  referer_slug?: string | undefined;
}

const initialAuthState: InitialAuthState = {
  isLoading: false,
  accessToken: null,
  isAdmin: false,
  refreshToken: null,
  isLoggedIn: false,
  isPasswordChanged: false,
  companyDetails: null,
  userType: undefined,
  referer_id: undefined,
  referer_slug: undefined,
};

export default initialAuthState;
